export const EVOMPASS_URL_SLUG = 'evompass';

export const PHONE_MASK = ['+', '5', '5', ' ', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
export const CPF_MASK = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

export const PHONE_REGEX_VALIDATION = /^\+55 ([(][1-9]{2}[)] )?9?[0-9]{4}[- ]?[0-9]{4}$/;
export const MOBILE_PHONE_REGEX_VALIDATION = /^\+55 ([(][1-9]{2}[)]) 9[0-9]{4}[-][0-9]{4}$/;
export const EMAIL_REGEX_VALIDATION = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const CPF_REGEX_VALIDATION = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
export const CPF_MASK_LENGTH = 14; // no formato descrito por CPF_REGEX_VALIDATION
export const CNPJ_REGEX_VALIDATION = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;
export const CNPJ_MASK_LENGTH = 18; // no formato descrito por CNPJ_REGEX_VALIDATION
export const UUID_REGEX_VALIDATION = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
export const RECEIPT_ATT_FILE_FORMAT_REGEX_VALIDATION = /\.(png|pdf|jpg|jpeg)$/i;
export const BIRTH_DATE_REGEX_VALIDATION = /^(?:0[1-9]|[12]\d|3[01])([\/.-])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/;

export const ROLE_AUTHORITIES = {
  ROLE_PMTARG_ADMIN: 'ROLE_PMTARG_ADMIN',
  ROLE_COMPANY_USER: 'ROLE_COMPANY_USER',
  ROLE_EMPLOYER_USER: 'ROLE_EMPLOYER_USER',
  ROLE_POS_AGENCY: 'ROLE_POS_AGENCY',
};

export const AUTHENTICATION_PROVIDERS = {
  APPLE: 'APPLE',
  GOOGLE: 'GOOGLE',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
};

export const posUserPermissionAuthorities = {
  'Dashboard': [
    { value: 'PERM_BO_POS_DASHBOARD', label: 'Dashboard' },
  ],
  'Movimentações financeiras': [
    { value: 'PERM_BO_COMPANY_INVOICES', label: 'Fatura' },
    { value: 'PERM_BO_COMPANY_STATEMENT', label: 'Extrato' },
  ],
};

export const companyUserPermissionAuthorities = {
  'Dashboard': [
    { value: 'PERM_BO_COMPANY_DASHBOARD', label: 'Dashboard' },
  ],
  'Cadastros': [
    { value: 'PERM_BO_COMPANY_EMPLOYEES', label: 'Contas/Colaboradores' },
  ],
  'Movimentações financeiras': [
    { value: 'PERM_BO_COMPANY_INVOICES', label: 'Faturas do empregador' },
    { value: 'PERM_BO_COMPANY_STATEMENT', label: 'Extrato do empregador' },
  ],
};

export const paymentArrangementUserPermissionAuthorities = {
  'Dashboard': [
    { value: 'PERM_BO_PMTARG_REAL_TIME_DASHBOARD', label: 'Dashboard tempo real' },
    { value: 'PERM_BO_PMTARG_TRANSACTION_DASHBOARD', label: 'Dashboard transações' },
    { value: 'PERM_BO_PMTARG_ENROLLMENTS_DASHBOARD', label: 'Dashboard cadastros' },
    { value: 'PERM_BO_PMTARG_CREDIT_AND_CONSUMPTION_DASHBOARD', label: 'Dashboard crédito e consumo' },
    { value: 'PERM_BO_PMTARG_FINANCIAL_TRANSACTIONS_DASHBOARD', label: 'Dashboard movimentação financeira' },
  ],
  'Cadastros': [
    { value: 'PERM_BO_PMTARG_COMPANIES', label: 'Empregadores' },
    { value: 'PERM_BO_PMTARG_USERS', label: 'Usuários' },
  ],
  'Ponto de Venda': [
    { value: 'PERM_BO_POS_AGENCY', label: 'Agências' },
    { value: 'PERM_BO_POS_AGENCY_USERS', label: 'Usuários Agências' },
    { value: 'PERM_BO_PMTARG_POS_USERS', label: 'Operadores PDV' },
    { value: 'PERM_BO_POS_TRANSACTIONS', label: 'Transações' },
  ],
  'Carteiras': [
    { value: 'PERM_BO_PMTARG_EVOM_ACCOUNT', label: 'Buscar/Contas' },
    { value: 'PERM_BO_PMTARG_WALLET_REQUESTS', label: 'Solicitações de carteiras' },
  ],
  'Movimentações financeiras': [
    { value: 'PERM_BO_PMTARG_PAYMENT_ARRANGEMENT_INVOICES', label: 'Liquidação de faturas' },
    { value: 'PERM_BO_PMTARG_CREDITS', label: 'Créditos' },
    { value: 'PERM_BO_PMTARG_EXPENSES', label: 'Consumo' },
  ],
  'Relatórios': [
    { value: 'PERM_BO_PMTARG_CREDIT_REPORT', label: 'Crédito diário em carteiras' },
    { value: 'PERM_BO_PMTARG_EXPENSES_REPORT', label: 'Consumo diário' },
    { value: 'PERM_BO_PMTARG_EXPORTS', label: 'Exportações' },
    { value: 'PERM_BO_POS_REPORTS', label: 'Vendas diárias - PDV' },
    { value: 'PERM_BO_POS_REPORTS', label: 'Vendas por Operador - PDV' },
    { value: 'PERM_BO_PMTARG_REPORT_TEMPLATE', label: 'Outros relatórios' },
  ],
  'Configurações': [
    { value: 'PERM_BO_PMTARG_WALLET_PARAMETERIZATION', label: 'Parametrização de carteiras' },
    { value: 'PERM_BO_PMTARG_PROMOTIONS_CASH_IN', label: 'Promoções de recarga' },
    { value: 'PERM_BO_PMTARG_PROMOTIONS_CASH_OUT', label: 'Promoções de consumo' },
  ],
};

export const LOGIN_TYPE_PHONE = [
  'FIREBASE_PHONE',
  'phone',
];

export const LOGIN_TYPE_EMAIL = [
  'FIREBASE_GMAIL',
  'google.com',
  'apple.com',
  'password',
];

export const EDITABLE_EMAIL_LOGIN_TYPE = [
    'FIREBASE_GMAIL',
    'password',
];

export const NOT_EDITABLE_EMAIL_LOGIN_TYPE = [
    'google.com',
    'apple.com',
];

export const AUTH_PROVIDER_TYPES_PHONE_NUMBER = [
  'PHONE',
];

export const AUTH_PROVIDER_TYPES_EMAIL_ADDRESS = [
  'EMAIL',
  'PASSWORD',
  'GOOGLE',
  'APPLE',
];

export const EXPECTED_SETTLEMENT_TYPE = [
  'MANUAL_CASH',
  'MANUAL_TRANSFER',
  'MANUAL_CASH_DEPOSIT',
  'GN_BANKING_BILLET',
  'MANUAL_PIX',
  'AUTO_COMPANY_BALANCE',
];

export const DEFAULT_EXPECTED_SETTLEMENT_TYPE = [
  'MANUAL_CASH',
  'MANUAL_TRANSFER',
  'MANUAL_CASH_DEPOSIT',
  'AUTO_COMPANY_BALANCE',
];

export const TOP_UP_INVOICE_TYPE = [
  'REGULAR',
  'COMPANY_BALANCE',
];

export const TRANSACTION_TYPE = [
  'TOP_UP',
  'GET_ON_TRANSPORT',
  'TRANSFER_INCOME',
  'TRANSFER_OUTCOME',
  'ROLLBACK',
  'ROAD_TICKET_SALE_PAYMENT',
  'TOP_UP_EXPIRATION',
];

export const TRANSACTION_STATUS = [
  'CREATED',
  'INVALID',
  'AUTHORIZED',
  'UNAUTHORIZED',
  'FAILED',
  'CANCELED',
  'FULL_REFUND',
  'PARTIAL_REFUND',
  'UNKNOWN_ERROR',
];

export const WALLET_TYPE = [
  'REGULAR',
  'WORKER',
  'STUDENT',
  'STUDENT_FREE',
  'ELDERLY',
  'SPECIAL',
  'GENERIC_1',
  'GENERIC_2',
  'GENERIC_3',
  'GENERIC_4',
  'GENERIC_5',
  'GENERIC_6',
  'GENERIC_7',
  'GENERIC_8',
  'GENERIC_9',
  'GENERIC_10',
];

export const IDENTIFICATION_METHOD_TYPE = [
  'QRCODE_MOBILE',
  'QRCODE_CARD',
  'QRCODE_BRACELET',
  'QRCODE_PAPER',
  'NFC_MOBILE',
  'NFC_CARD',
  'NFC_BRACELET',
  'VOUCHER',
];

export const REPORT_EXECUTION_LINE_LEVEL = [
  'INFO',
  'WARNING',
  'ERROR',
  'CRITICAL',
];

export const SYSTEM_USERS = [
  'webhook_pix',
  'scheduler_pix',
  'webhook_banking_billet',
  'scheduler_banking_billet',
  'business_rule'
];

export const PROMOTION_CASH_IN_TYPE = [
  'DISCOUNT',
  'CASHBACK',
];

export const EMPLOYER_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  COMPANY: 'COMPANY',
};

export const RECEIPT_DENIED_REASON_REVIEW = [
  'WRONG_ATTACHMENT',
  'UNREADABLE',
  'OTHER',
];

export const colors = [
  "#05386b", "#055e6b", "#01b282", "#036042", "#004D6F",
  "#00738E", "#009A99", "#96DF7D", "#cddc39", "#F9F871",
  "#7A7B1B", "#384B41", "#B1B050", "#006A6A", "#007463",
  "#00C6BD", "#1A857F", "#334B49", "#007D55", "#0DB387",
];

export const WALLET_TYPE_COLORS = {
  student_free: '#004D6F',
  regular: '#00738E',
  worker: '#009A99',
  student: '#30BF90',
  elderly: '#96DF7D',
  special: '#aaa900',
  generic_1: '#053225',
  generic_2: '#60a561',
  generic_3: '#dfb118',
  generic_4: '#c99649',
  generic_5: '#a7754d',
  generic_6: '#210b2c',
  generic_7: '#5f0f40',
  generic_8: '#6f4b7c',
  generic_9: '#8a5293',
  generic_10: '#9e75cc',
};

export const IDENTIFICATION_METHOD_TYPE_COLORS = {
  nfc_bracelet: '#004D8F',
  qrcode_mobile: '#004D6F',
  qrcode_card: '#00738E',
  qrcode_bracelet: '#009A99',
  qrcode_paper: '#30BF90',
  nfc_mobile: '#96DF7D',
  nfc_card: '#aaa900'
};

export const BILLING_CYCLE_TYPE = [
  'MANUAL',
  'DAILY',
  'WEEKLY',
  'MONTHLY',
];

export const RECHARGE_BALANCE_TYPE = [
  'PRE_PAID',
  'POST_PAID',
];

export const COMPANY_TRANSACTION_TYPE = [
  'INVOICE',
  'TRANSFER',
  'VOID',
];

export const AGENCY_TRANSACTION_TYPE = [
  ...COMPANY_TRANSACTION_TYPE,
  'POS_TRANSACTION',
];